@use 'sass:map';
@use '@angular/material' as mat;

/** Reusable var **/
$pollsterColor: #ef7030;
$lightPollsterColor: rgba($pollsterColor, 0.75);
$boxShadow: 1px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
$borderRadiusNormal: 6px;


/** Default theme **/
$theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$orange-palette),
    accent: mat.define-palette(mat.$blue-grey-palette)
  )
));

@include mat.all-component-themes($theme);

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: $boxShadow;
}

.mat-card {
  border-radius: $borderRadiusNormal !important;
}

.mat-dialog-container {
  border-radius: $borderRadiusNormal !important;
}

.mat-menu-panel {
  overflow: hidden !important;
  border-radius: $borderRadiusNormal !important;
}

/** Reusable class **/
.text-center {
  text-align: center;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-text {
  color: #ffffff;
}

.black-text {
  color: #000000;
}

.font-22px {
  font-size: 22px;
}

.font-20px {
  font-size: 20px;
}

.font-16px {
  font-size: 16px;
}

.font-14px {
  font-size: 14px;
}

.font-12px {
  font-size: 12px;
}

.semi-bold {
  font-weight: 500;
}

.no-scroll-bar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    width: 0;
    height: 0;
  }
}

.default-scroll-bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroll-bar-orange-thumb {
  @extend .default-scroll-bar;

  &::-webkit-scrollbar-thumb {
    background-color: $lightPollsterColor;
  }
}

.scroll-bar-gray-thumb {
  @extend .default-scroll-bar;

  &::-webkit-scrollbar-thumb {
    background-color: gray;
  }
}

.light-orange-bg-color {
  background-color: #fffefc;
}

.orange-bg-color {
  background-color: $lightPollsterColor;
}

.light-gray-color {
  background-color: rgba(117, 117, 117, 0.2);
}

.white-bg-color {
  background-color: #ffffff;
}

.light-black-bg-color {
  background-color: rgba(0, 0, 0, 0.7);
}

.no-margin {
  margin: 0 !important;
}

.black-hover {
  color: #000000;

  border-radius: 6px;
  transition-duration: 0.5s;
  cursor: pointer;

  &:hover, &:active, &:focus {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.85);
  }
}

.strong-black-hover {
  color: #000000;

  border-radius: 6px;
  transition-duration: 0.5s;
  cursor: pointer;

  &:hover, &:active, &:focus {
    background-color: rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.85);
  }
}

.orange-hover {
  color: #000000;

  border-radius: 6px;
  transition-duration: 0.5s;
  cursor: pointer;

  &:hover, &:active, &:focus {
    background-color: rgba($lightPollsterColor, 0.6);
    color: rgba(0, 0, 0, 0.85);
  }
}

.default-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.box {
  border-radius: 6px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 25%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.white-btn {
  border-radius: 6px;
  //box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 6%), 1px 3px 10px 0px rgb(0 0 0 / 5%), 0px 1px 7px 0px rgb(0 0 0 / 8%);
}

.mat-form-field-no-bottom-space {
  .mat-form-field {
    line-height: 1.4;
  }

  .mat-form-field-wrapper {
    margin-bottom: -1.25em !important;
  }
}


/** DashBoard **/
.dashed-line {
  border: none;
  border-top: 2px dotted gray;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}


/** Sidebar **/
.sidebar-shadow {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.sidebar-link {
  height: 55px;
  transition: 0.4s ease;
  cursor: pointer;

  &:hover {
    border-top: 4px solid $lightPollsterColor;
    border-bottom: 4px solid $lightPollsterColor;
  }
}

.active-link {
  border-top: 4px solid $lightPollsterColor;
  border-bottom: 4px solid $lightPollsterColor;
}

.campaign-active {
  background-color: rgba($lightPollsterColor, 0.6);
  color: rgba(0, 0, 0, 0.85);
}

hr {
  border-top: 1px rgba(0, 0, 0, 0.7) dotted;
}

/** Header **/
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: plum;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}

.mobile-menu-icon {
  font-size: 45px;
}

.mobile-active-link {
  background: rgba(239, 112, 48, 0.5);
  border-radius: 6px;
}

.mobile-campaign-active {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.15);
}

/** PDF **/
.ng2-pdf-viewer-container {
  @extend .scroll-bar-gray-thumb;
}

pdf-viewer {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


/** Table **/
mat-paginator {
  width: 100%;
  min-width: 950px;
}

.pollster-table {
  width: 100%;
  min-width: 950px;

  th {
    border: 1px solid #000000;
    text-align: center;
  }

  td {
    border: 1px solid #000000;
    text-align: center;
  }

  tr:last-child td {
    border-bottom: 1px solid #000000;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 0;
  }

  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 0;
  }

  .mat-sort-header-content {
    width: 70%;
    display: block;
    text-align: end;
  }
}

.table-hover {
  tbody {
    tr {
      @extend .strong-black-hover;
    }
  }
}

.mat-elevation-z8 {
  box-shadow: none !important;
}

.mat-table {
  background-color: transparent !important;
}
